import ScrollMagic from 'scrollmagic';

import {scrolEvents} from './default';
import {index} from './index';
import {works} from './works';
import {vm} from './vm';
require("fslightbox");

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    
    vm();
    scrolEvents(controller , scene);
    index(controller , scene);
    works(controller , scene);
});