import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';

export function scrolEvents(controller , scene){
    if(document.getElementById('site-header')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * .75
            })
            .setClassToggle('#site-header','active').addTo(controller)
        )
    };
	
    if(document.getElementById('sidebtn')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle('#sidebtn','active').addTo(controller)
        )
    }
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}
    
	const scroll_custum_name = 'scroll-custum';
	const scroll_custum_elem = document.getElementsByClassName(scroll_custum_name);
	if(scroll_custum_elem.length > 0){
		for(let i = 0; i < scroll_custum_elem.length; i++){
			let custum_node = scroll_custum_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: custum_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(custum_node,'_active').addTo(controller)
			)
		}
	}
	
	const scroll_img_name = 'scroll-img';
	const scroll_img_elem = document.getElementsByClassName(scroll_img_name);
	if(scroll_img_elem.length > 0){
		for(let i = 0; i < scroll_img_elem.length; i++){
			let scroll_img_node = scroll_img_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_img_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_img_node,'__active').addTo(controller)
			)
		}
	}
    
    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
        offset: 0
    });
	
	const fullSize = document.getElementsByClassName('fullSize');
	if(fullSize.length > 0){
		for(let i = 0; i < fullSize.length; i++){
			fullSize[i].style.maxHeight = window.innerHeight + 'px';
		}
	}
	
	
	const dataset = document.querySelectorAll('.is_date');
	dataset.forEach( (el)=> {
		let today = new Date();
		today.setDate(today.getDate());
		let yyyy = today.getFullYear();
		let mm = ('0'+(today.getMonth()+1)).slice(-2);
		let dd = ('0'+today.getDate()).slice(-2);
		el.value = yyyy + '-' + mm + '-' + dd;
	});
	
}