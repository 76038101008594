import Vue from 'vue/dist/vue.min';

export function vm(){
  const v = new Vue({
    el: '#site-header',
    data: {
      menu: false,
    }
  });
	
	const s = new Vue({
		el: '#staff-app',
		data: {
			modal: false,
			img: '',
			job: '',
			name: '',
			ary: [],
			works: [],
		},
		methods: {
			modalData: function(img, job, name, ary, works){
				this.img = img
				this.job = job
				this.name = name
				this.ary = ary
				this.works = works
				this.modal = true
			}
		}
	})	
}