import Swiper from 'swiper/swiper-bundle.min';

export function works(){
	const worksSlide = document.getElementById('worksSlide');
	if(worksSlide){
		
		if(document.querySelectorAll('.swiper-slide').length  == 1){
			worksSlide.classList.add('single');
		}else{
			new Swiper(worksSlide, {
				spaceBetween: 10,
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				speed: 600,
				grabCursor: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
			})
		}
	}	
};